<template>
  <div class="video-item-wrapper">
    <div class="video_poster d-flex align-center justify-center">
      <img v-if="loading" class="poster" src="/images/loader.gif" />
      <video v-else width="200" height="115" class="depox-video cursor-pointer" :id="`depox-video-${index}`" @click="goDetail(item)">
        <source :src="`${item.video}#t=1`" />
      </video>
    </div>
    <div class="video-actions d-flex justify-space-between">
      <div class="d-flex flex-column ml-2" :class="{ 'justify-center': !item.title }">
        <span v-if="item.title" class="video-title py-1">{{ item.title }}</span>
        <span v-if="item.date" class="video-created-date py-1">{{ item.date | formatDate }}</span>
        <span v-if="downloadStatus" class="depox-download py-1">{{ $t('downloading') }}: %{{ downloadStatus }}</span>
      </div>
      <v-menu class="video-actions-menu" offset-y v-if="!deleteStatus">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="more-actions" v-bind="attrs" v-on="on">
            <AppIcon class="ml-1" size="16" icon="more-vertical" />
          </v-btn>
        </template>
        <v-list class="video-action-list py-0" :class="{ 'cursor-pointer': !downloadStatus }">
          <v-list-item :disabled="Boolean(downloadStatus)" @click="download(item)">
            <v-list-item-content>
              <div class="d-flex align-center">
                <span class="list-item-icon mr-2 d-flex">
                  <AppIcon icon="download-cloud" width="14" height="14" />
                </span>
                <span class="list-item-text">
                  {{ $t('videos.actions.downloadRecording') }}
                </span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list class="video-action-list py-0">
          <v-divider class="opacity-50"></v-divider>
        </v-list>
        <v-list class="video-action-list py-0 cursor-pointer">
          <v-list-item @click="remove(item)">
            <v-list-item-content>
              <div class="d-flex align-center">
                <span class="list-item-icon mr-2 d-flex">
                  <AppIcon icon="trash" width="14" height="14" color="var(--v-milano-red-base)" />
                </span>
                <span class="list-item-text delete-action" style="">
                  {{ $t('videos.actions.deleteRecording') }}
                </span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import XperdriveLib from 'xperdrive-lib';
import xssService from '@/services/xss.service';
import { NOTIFICATION } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';
import { formatDate } from '@/helpers/date';

export default {
  name: 'VideoItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      downloadStatus: null,
      deleteStatus: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const videoEl = document.getElementById(`depox-video-${this.index}`);
      videoEl.onloadstart = () => {
        this.loading = true;
      };
      videoEl.oncanplay = () => {
        this.loading = false;
      };
    });
  },
  methods: {
    ...mapActions(NOTIFICATION, ['showToastNotification']),

    download(file) {
      if (this.downloadStatus) {
        return;
      }
      const { fileKey, title } = file;
      const fileName = `${title || formatDate(file.date)}.mp4`;
      XperdriveLib.FileDownload({ fileKey, fileName }, (progress) => {
        const p = Math.floor((progress.loaded * 100) / progress.total);
        this.downloadStatus = p;

        if (p === 100) {
          setTimeout(() => {
            this.downloadStatus = null;
          }, 1000);
        }
      });
    },
    share(item) {
      // eslint-disable-next-line no-console
      console.log(item);
    },
    async remove(item) {
      this.deleteStatus = true;
      try {
        const res = await xssService.deleteUserRecord(item.sessionRecordingId);
        if (res.status === 200) {
          this.$emit('fetch');
        }
      } catch (error) {
        this.showToastNotification({ body: this.$t('error.couldNotDeleteVideo'), config: { type: TOAST_TYPE.ERROR } });
      } finally {
        this.deleteStatus = false;
      }
    },
    goDetail(item) {
      this.$router.push({
        name: 'VideoDetail',
        params: { item },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.video_poster {
  position: relative;
  width: 200px;
  height: 115px;
  .poster {
    width: 32px;
    height: 32px;
  }
  .depox-video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.video-item-wrapper {
  background: var(--v-night-rider-base);
  border-radius: 8px;
  width: 200px;
  .video-actions {
    width: 200px;
    .video-title {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
    }
    .video-created-date {
      font-weight: 500;
      font-size: 8px;
      line-height: 9px;
      color: var(--v-dark-gray-base);
    }
    .depox-download {
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      color: var(--v-cyan-darken2);
    }
  }
}
.v-menu__content {
  border-radius: 12px;
  .video-action-list {
    border-radius: 0px;
    .list-item-text {
      font-size: 12px;
      line-height: 11px;
      &.delete-action {
        color: var(--v-milano-red-base);
      }
    }
  }
}
</style>

