var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "videos-wrapper" },
    [
      _c("div", { staticClass: "videos-title" }, [
        _vm._v(_vm._s(_vm.$t("videos.title"))),
      ]),
      _c("div", { staticClass: "videos-content mt-1" }, [
        _vm._v(_vm._s(_vm.$t("videos.content"))),
      ]),
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "video-list mt-5" },
            [
              _c("VideosList", {
                attrs: { items: _vm.items },
                on: { fetch: _vm.fetch },
              }),
            ],
            1
          )
        : _c("v-skeleton-loader", { attrs: { type: "list-item-avatar" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }