var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "video-item-wrapper" }, [
    _c(
      "div",
      { staticClass: "video_poster d-flex align-center justify-center" },
      [
        _vm.loading
          ? _c("img", {
              staticClass: "poster",
              attrs: { src: "/images/loader.gif" },
            })
          : _c(
              "video",
              {
                staticClass: "depox-video cursor-pointer",
                attrs: {
                  width: "200",
                  height: "115",
                  id: `depox-video-${_vm.index}`,
                },
                on: {
                  click: function ($event) {
                    return _vm.goDetail(_vm.item)
                  },
                },
              },
              [_c("source", { attrs: { src: `${_vm.item.video}#t=1` } })]
            ),
      ]
    ),
    _c(
      "div",
      { staticClass: "video-actions d-flex justify-space-between" },
      [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column ml-2",
            class: { "justify-center": !_vm.item.title },
          },
          [
            _vm.item.title
              ? _c("span", { staticClass: "video-title py-1" }, [
                  _vm._v(_vm._s(_vm.item.title)),
                ])
              : _vm._e(),
            _vm.item.date
              ? _c("span", { staticClass: "video-created-date py-1" }, [
                  _vm._v(_vm._s(_vm._f("formatDate")(_vm.item.date))),
                ])
              : _vm._e(),
            _vm.downloadStatus
              ? _c("span", { staticClass: "depox-download py-1" }, [
                  _vm._v(
                    _vm._s(_vm.$t("downloading")) +
                      ": %" +
                      _vm._s(_vm.downloadStatus)
                  ),
                ])
              : _vm._e(),
          ]
        ),
        !_vm.deleteStatus
          ? _c(
              "v-menu",
              {
                staticClass: "video-actions-menu",
                attrs: { "offset-y": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "more-actions",
                                  attrs: { icon: "" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("AppIcon", {
                                staticClass: "ml-1",
                                attrs: { size: "16", icon: "more-vertical" },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1239667873
                ),
              },
              [
                _c(
                  "v-list",
                  {
                    staticClass: "video-action-list py-0",
                    class: { "cursor-pointer": !_vm.downloadStatus },
                  },
                  [
                    _c(
                      "v-list-item",
                      {
                        attrs: { disabled: Boolean(_vm.downloadStatus) },
                        on: {
                          click: function ($event) {
                            return _vm.download(_vm.item)
                          },
                        },
                      },
                      [
                        _c("v-list-item-content", [
                          _c("div", { staticClass: "d-flex align-center" }, [
                            _c(
                              "span",
                              { staticClass: "list-item-icon mr-2 d-flex" },
                              [
                                _c("AppIcon", {
                                  attrs: {
                                    icon: "download-cloud",
                                    width: "14",
                                    height: "14",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("span", { staticClass: "list-item-text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("videos.actions.downloadRecording")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-list",
                  { staticClass: "video-action-list py-0" },
                  [_c("v-divider", { staticClass: "opacity-50" })],
                  1
                ),
                _c(
                  "v-list",
                  { staticClass: "video-action-list py-0 cursor-pointer" },
                  [
                    _c(
                      "v-list-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.remove(_vm.item)
                          },
                        },
                      },
                      [
                        _c("v-list-item-content", [
                          _c("div", { staticClass: "d-flex align-center" }, [
                            _c(
                              "span",
                              { staticClass: "list-item-icon mr-2 d-flex" },
                              [
                                _c("AppIcon", {
                                  attrs: {
                                    icon: "trash",
                                    width: "14",
                                    height: "14",
                                    color: "var(--v-milano-red-base)",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "list-item-text delete-action" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("videos.actions.deleteRecording")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }