<template>
  <div class="videos-wrapper">
    <div class="videos-title">{{ $t('videos.title') }}</div>
    <div class="videos-content mt-1">{{ $t('videos.content') }}</div>
    <div class="video-list mt-5" v-if="!loading">
      <VideosList :items="items" @fetch="fetch" />
    </div>
    <v-skeleton-loader v-else type="list-item-avatar"></v-skeleton-loader>
  </div>
</template>
<script>
import VideosList from '@/components/VideosList';
import xssService from '@/services/xss.service';
import { consoleError } from 'xpermeet-lib';

export default {
  name: 'Videos',
  components: { VideosList },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      try {
        const { data } = await xssService.fetchUserSessionRecording();
        this.items = data.map((item) => {
          return {
            video: item.preSignedUrl,
            date: new Date(item.udate),
            fileKey: item.fileKey,
            providerInfos: item.providerInfos,
            title: item?.sessionName || item?.providerInfos[0]?.title,
            sessionRecordingId: item.sessionRecordingId,
          };
        });
      } catch (error) {
        consoleError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.videos-wrapper {
  .videos-title {
    font-size: 17px;
    line-height: 20px;
    color: var(--v-text-gray-base);
  }
  .videos-content {
    font-size: 13px;
    line-height: 15px;
    color: var(--v-dark-gray-base);
    opacity: 0.5;
  }
}
</style>

