var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.items.length
        ? _c(
            "v-row",
            _vm._l(_vm.items, function (item, key) {
              return _c(
                "v-col",
                { key: key, attrs: { cols: "4" } },
                [
                  _c("VideoItem", {
                    attrs: { item: item, index: key },
                    on: {
                      fetch: function ($event) {
                        return _vm.$emit("fetch")
                      },
                    },
                  }),
                ],
                1
              )
            }),
            1
          )
        : _c("div", [_vm._v(_vm._s(_vm.$t("noRecordedVideo")))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }