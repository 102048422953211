<template>
  <div>
    <v-row v-if="items.length">
      <v-col cols="4" v-for="(item, key) in items" :key="key">
        <VideoItem :item="item" :index="key" @fetch="$emit('fetch')" />
      </v-col>
    </v-row>
    <div v-else>{{ $t('noRecordedVideo') }}</div>
  </div>
</template>
<script>
import VideoItem from '@/components/VideoItem';

export default {
  name: 'VideosList',
  components: { VideoItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

